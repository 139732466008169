import * as React from 'react'

import { cn } from '@/lib/utils/utils'
import { Slot } from '@radix-ui/react-slot'
import { cva, type VariantProps } from 'class-variance-authority'

export let CARD_VARIANTS: any

const card = cva('prettyScrollBar flex flex-col bg-card text-card-foreground rounded-lg h-fit overflow-hidden', {
  variants: CARD_VARIANTS = {
    variant: {
      default: 'bg-card',
      ghost: 'bg-transparent',
      consent: 'bg-transparent overflow-auto', 
      outline: 'bg-transparent border border-border',
      shadow: 'shadow-sm border border-muted'
    }
  },
  defaultVariants: {
    variant: 'default'
  }
})

export interface CardProps extends React.HTMLAttributes<HTMLDivElement>, VariantProps<typeof card> {
  asChild?: boolean
}

export const Card = React.forwardRef<HTMLDivElement, CardProps>(
  ({ variant, className, asChild = false, ...props }, ref) => {
    const Comp = asChild ? Slot : 'div'
    return (
      <Comp
        id='CARD'
        ref={ref}
        className={cn(card({ variant }), className)}
        {...props}
      />
    )
  })
Card.displayName = 'Card'

export const CardHeader = React.forwardRef<HTMLDivElement, React.HTMLAttributes<HTMLDivElement>>(
  ({ className, ...props }, ref) => (
    <div
      id='CARD_HEADER'
      ref={ref}
      className={cn('flex flex-col gap-text p-card', className)}
      {...props}
    />
  ))
CardHeader.displayName = 'CardHeader'

export const CardTitle = React.forwardRef<HTMLParagraphElement, React.HTMLAttributes<HTMLDivElement>>(
  ({ className, ...props }, ref) => (
    <h3
      id='CARD_TITLE'
      ref={ref}
      className={cn('text-cardTitle font-cardTitle leading-none tracking-tight', className)}
      {...props}
    />
  ))
CardTitle.displayName = 'CardTitle'

export const CardDescription = React.forwardRef<HTMLParagraphElement, React.HTMLAttributes<HTMLDivElement>>(
  ({ className, ...props }, ref) => (
    <div
      id='CARD_DESCRIPTION'
      ref={ref}
      className={'text-cardDesc ' + cn('font-cardDesc text-muted-foreground', className)}
      {...props}
    />
  ))
CardDescription.displayName = 'CardDescription'

export const CardContent = React.forwardRef<HTMLDivElement, React.HTMLAttributes<HTMLDivElement>>(
  ({ className, ...props }, ref) => (
    <div
      id='CARD_CONTENT'
      ref={ref}
      className={cn('flex grow flex-col gap-card p-card pt-0', className)}
      {...props}
    />
  ))
CardContent.displayName = 'CardContent'

export const CardFooter = React.forwardRef<HTMLDivElement, React.HTMLAttributes<HTMLDivElement>>(
  ({ className, ...props }, ref) => (
    <div
      id='CARD_FOOTER'
      ref={ref}
      className={cn('flex items-center', className)}
      {...props}
    />
  ))
CardFooter.displayName = 'CardFooter'

