import { cn } from '@/lib/utils/utils'
import { Slot } from '@radix-ui/react-slot'
import { cva, type VariantProps } from 'class-variance-authority'
import * as React from 'react'

export const flexVariants = cva(
  'flex flex-wrap',
  {
    variants: {
      justify: {
        start: 'justify-start',
        center: 'justify-center',
        end: 'justify-end',
        between: 'justify-between',
        around: 'justify-around'
      },
      items: {
        start: 'items-start',
        center: 'items-center',
        end: 'items-end',
        stretch: 'items-stretch',
        baseline: 'items-baseline'
      },
      size: {
        xs: 'p-0 gap-0',
        sm: 'p-2 gap-2',
        md: 'p-4 gap-4',
        lg: 'p-8 gap-8'
      }
    },
    defaultVariants: {
      justify: 'start',
      items: 'start',
      size: 'xs'
    }
  }
)

export interface FlexProps extends React.HTMLAttributes<HTMLDivElement>, VariantProps<typeof flexVariants> {
  asChild?: boolean
}

export const Flex = React.forwardRef<HTMLDivElement, FlexProps>(
  ({ className, justify, items, size, asChild = false, ...props }, ref) => {
    const Comp = asChild ? Slot : 'div'
    return (
      <Comp
        className={cn(flexVariants({ justify, items, size, className }))}
        ref={ref}
        {...props}
      />
    )
  }
)
Flex.displayName = 'Flex'

export const FlexCol = React.forwardRef<HTMLDivElement, FlexProps>(
  ({ className, justify, items, size, asChild = false, ...props }, ref) => {
    const Comp = asChild ? Slot : 'div'
    return (
      <Comp
        className={cn(flexVariants({ justify, items, size, className }), 'flex-col')}
        ref={ref}
        {...props}
      />
    )
  }
)
FlexCol.displayName = 'FlexCol'
