import { siteConfig } from '@/config/site.config'
import { cn } from '@/lib/utils/utils'
import Image from 'next/image'
import Link from 'next/link'
import { getLocalizedUri, setLangCode } from '../i18n/lang-context'


export const Logo = ({ imageSrc, imageDarkSrc, uri = '', onClick, langCode, className = '' }: { imageSrc: string, imageDarkSrc: string, uri?: string, onClick?: () => void, langCode?: string, className?: string }) => {

  if (langCode) setLangCode(langCode)

  return (
    <Link href={uri !== undefined ? getLocalizedUri(uri) : ''} onClick={onClick} className={cn('relative', className)}>
      <Image alt={siteConfig.appName}
        src={imageSrc}
        width={0}
        height={0}
        sizes='33vw'
        priority={true}
        className='block h-full w-auto object-cover transition-all hover:scale-110 dark:hidden'
      />
      <Image alt={siteConfig.appName}
        src={imageDarkSrc}
        width={0}
        height={0}
        sizes='33vw'
        priority={true}
        className='hidden h-full w-auto object-cover transition-all hover:scale-110 dark:block'
      />
    </Link>
  )
}

export const LogoHorizontal = ({ bj = false, uri = '', onClick, langCode, className = '' }: { bj?: boolean, uri?: string, onClick?: () => void, langCode?: string, className?: string }) => {

  const imageSrc = bj ? siteConfig.logo.horizontalBj : siteConfig.logo.horizontal
  const imageDarkSrc = bj ? siteConfig.logo.horizontalBjWhite : siteConfig.logo.horizontalWhite

  const sizeClass = 'h-[40px] md:h-[49px] w-auto'
  const sizeClassBJ = 'h-[60px] md:h-[74] w-auto'

  return (
    <Logo imageSrc={imageSrc} imageDarkSrc={imageDarkSrc} uri={uri} onClick={onClick} langCode={langCode} className={cn(bj ? sizeClassBJ : sizeClass, className)} />
  )
}

export const LogoVertical = ({ uri = '', onClick, langCode, className = '' }: { bj?: boolean, uri?: string, onClick?: () => void, langCode?: string, className?: string }) => {

  const imageSrc = siteConfig.logo.vertical
  const imageDarkSrc = siteConfig.logo.verticalWhite

  const sizeClass = 'h-[80px] w-auto'

  return (
    <Logo imageSrc={imageSrc} imageDarkSrc={imageDarkSrc} uri={uri} onClick={onClick} langCode={langCode} className={cn(sizeClass, className)} />
  )
}
