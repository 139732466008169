'use client'

import { useDialogActions } from '@/components/dialog'
import { I18nDictionary, useI18n } from '@/components/i18n/lang-context'
import { Button } from '@/components/ui/button'
import { Card, CardContent, CardFooter, CardHeader, CardTitle } from '@/components/ui/card'
import { Flex, FlexCol } from '@/components/ui/flex'
import { siteConfig } from '@/config/site.config'
import {
  CONSENTS, CONSENT_COOKIE_ALL,
  CONSENT_COOKIE_DURATION,
  CONSENT_COOKIE_I18N,
  CONSENT_COOKIE_NONE,
  ConsentState,
  calculateExpiresAt,
  useConsent
} from '@/contexts/ConsentContext'
import { useMounted } from '@/lib/utils/utils-client'
import { useEffect, useState } from 'react'
import MD from '../markdown'
import { Checkbox } from '../ui/checkbox'
import { LogoVertical } from './logo'


const I18N: I18nDictionary = {
  welcomeContent: {
    en: `
**${siteConfig.appName}** and its partners wish to use cookies and process your personal data
(including browsing data, data provided in your account and/or during the use of services, etc.) for:

---

* Personalize your experience
* Audience measurement
* Follow up on a request

---

You can allow or refuse all or part of these data processing activities, which are based on your consent or on the legitimate
interest of our partners, except for cookies and/or trackers necessary for the functioning of this site. You can change
your choices at any time by clicking on "Cookie Preferences" at the bottom of each page. To learn more, please consult
our [privacy policy](/legal/privacy-policy).
  `,
    fr: `
**${siteConfig.appName}** et ses partenaires souhaitent utiliser des cookies et traiter vos données personnelles
(données de navigation, données renseignées lors de l'utilisation des services, etc.) pour :

---

* Personaliser votre expérience
* Mesurer l'audience
* Vous communiquer des informations suite à une demande

---

Vous pouvez autoriser ou refuser tout ou partie de ces traitements de données qui sont basés sur votre consentement ou sur l'intérêt
légitime de nos partenaires, à l'exception des cookies et/ou traceurs nécessaires au fonctionnement de ce site. Vous pouvez modifier
vos choix à tout moment en cliquant sur "Cookies" au bas de chaque page. Pour en savoir plus, consultez notre
[politique de confidentialité](/legal/privacy-policy).
`
  },
  simpleContent: {
    en: `
To provide us with this information, you must agree to its use in the context of any resulting connection and in accordance with our [privacy policy](/legal/privacy-policy).
  `,
    fr: `
Pour pouvoir nous transmettre ces informations, vous devez accepter qu'elles soient utilisées dans le contexte de la mise en relation qui peut en découler et dans le cadre de notre [politique de confidentialité](/legal/privacy-policy).
`
  },
  advanced: {
    button: { en: 'Configure', fr: 'Paramétrer' },
    consents: CONSENT_COOKIE_I18N,
    desc: {
      en: `
We and our selected partners process personal data related to your browsing of our content for the purposes listed below.
To learn more about our practices regarding personal data protection and how we use your data, you can consult our
[privacy policy](/legal/privacy-policy).
      `,
      fr: `
Nous et nos partenaires sélectionnés traitons des données personnelles en lien avec votre navigation sur nos contenus pour les finalités listées ci-dessous.
Pour en savoir plus sur nos pratiques en matière de protection des données personnelles et la façon dont nous utilisons vos données, vous pouvez consulter notre
[politique de confidentialité](/legal/privacy-policy).
      `
    },
    info: {
      en: `
This site and its partners may also carry out the following data processing activities: Ensure security, prevent
fraud and debug, Technically distribute content, Match and combine offline data sources.
      `,
      fr: `
Ce site et ses partenaires pourront également réaliser les traitements de données suivants : Assurer la sécurité,
prévenir la fraude et déboguer, Diffuser techniquement le contenu, Mettre en correspondance et
combiner des sources de données hors ligne.
      `
    }
  },
  save: { en: 'Save', fr: 'Enregistrer' },
  acceptAll: { en: 'Accept all', fr: 'Accepter tout' },
  acceptNone: { en: 'Reject all', fr: 'Rejeter tout' },
  authorize: { en: 'Authorize', fr: 'Autoriser' },
  refuse: { en: 'Refuse', fr: 'Refuser' }
}

const I18NLink: I18nDictionary = {
  cookiesConsent: { en: 'Cookies', fr: 'Cookies' }
}

export function useCookiesConsentDialog(onConsent?: () => void) {
  const { openDialog } = useDialogActions()
  const { consent } = useConsent()

  const openCookiesConsentDialog = (mode: 'welcome' | 'advanced' | 'simple') => {
    openDialog({ content: <CookieConsentDialogContent initialMode={mode} onConsent={onConsent} /> })
  }

  useEffect(() => {
    if (!consent.init) openCookiesConsentDialog('welcome')
  }, [consent.analytics])

  return { openCookiesConsentDialog }
}

export function CookieConsentDialogContent({ initialMode, onConsent }: { initialMode: 'welcome' | 'advanced' | 'simple', onConsent?: () => void }) {

  const [mode, setMode] = useState<'welcome' | 'advanced' | 'simple'>(initialMode)
  const { consent, setConsent } = useConsent()
  const [tempConsent, setTempConsent] = useState<ConsentState>(consent)
  const i18n = useI18n(I18N)
  const { closeDialog } = useDialogActions()

  const save = (newConsent: ConsentState = { ...tempConsent, init: true }) => {
    setConsent({ ...tempConsent })
    newConsent.init = true
    newConsent.expiresAt = calculateExpiresAt(CONSENT_COOKIE_DURATION)
    setConsent({ ...newConsent })
    closeDialog()
  }

  const handleConsentChange = (consentType: keyof ConsentState, value: boolean) => {
    setTempConsent((prevTempConsent) => ({
      ...prevTempConsent,
      [consentType]: value
    }))
  }

  const WelcomeContent = () => (
    <FlexCol>
      <MD textAlign='justify' onClickLink={closeDialog}>
        {i18n.welcomeContent}
      </MD>
    </FlexCol>
  )

  const SimpleContent = () => (
    <FlexCol>
      <MD textAlign='justify' onClickLink={closeDialog}>
        {i18n.simpleContent}
      </MD>
    </FlexCol>
  )

  const AdvancedContent = () => (
    <FlexCol className='w-full gap-4'>
      <div className='text-xs sm:text-sm'>
        <MD textAlign='justify' onClickLink={closeDialog}>{i18n.advanced.desc}</MD>
      </div>
      <FlexCol className='w-full'>
        {CONSENTS.map(consentType => (
          <Flex key={consentType} className='w-full flex-nowrap items-center justify-between gap-2 rounded-sm p-1 hover:bg-muted'>
            <div className='text-xs sm:text-sm'>
              <MD>{' * ' + i18n.advanced.consents[consentType].title}</MD>
            </div>
            <Checkbox
              checked={tempConsent[consentType]}
              onCheckedChange={(checked: boolean) => handleConsentChange(consentType, checked)}
              disabled={consentType === 'essential'} />
          </Flex>
        ))}
      </FlexCol>
      <div className='text-xs sm:text-sm'>
        <MD textAlign='justify' onClickLink={closeDialog}>{i18n.advanced.info}</MD>
      </div>
    </FlexCol>
  )

  return (

    <Card variant='consent' className='w-full p-1 sm:w-fit sm:min-w-[640px] sm:max-w-[640px] sm:p-4' style={{ margin: '20px' }}>

      <CardHeader className='w-full !gap-0 !p-6'>
        <CardTitle className='flex w-full justify-center'>
          <LogoVertical />
        </CardTitle>
      </CardHeader>

      <CardContent className='w-full grow !gap-0 !p-4 !py-0 sm:!py-2'>
        {(() => {
          switch (mode) {
            case 'welcome':
              return <WelcomeContent />
            case 'advanced':
              return <AdvancedContent />
            case 'simple':
              return <SimpleContent />
            default:
              return null
          }
        })()}
      </CardContent>

      <CardFooter id='FOOTER' className='flex w-full flex-wrap justify-center gap-5 p-5'>
        {(() => {
          switch (mode) {
            case 'welcome':
              return (
                <>
                  <Button type='button' variant='outline' size='sm' onClick={() => setMode('advanced')}>
                    {i18n.advanced.button}
                  </Button>
                  <Button type='button' variant='outline' size='sm' onClick={() => save(CONSENT_COOKIE_NONE())}>
                    {i18n.acceptNone}
                  </Button>
                  <Button type='button' variant='outline' size='sm' onClick={() => save(CONSENT_COOKIE_ALL())}>
                    {i18n.acceptAll}
                  </Button>
                </>
              )
            case 'advanced':
              return (
                <>
                  <Button type='button' variant='outline' size='sm' onClick={() => save()}>
                    {i18n.save}
                  </Button>
                  <Button type='button' variant='outline' size='sm' onClick={() => save(CONSENT_COOKIE_NONE())}>
                    {i18n.acceptNone}
                  </Button>
                  <Button type='button' variant='outline' size='sm' onClick={() => save(CONSENT_COOKIE_ALL())}>
                    {i18n.acceptAll}
                  </Button>
                </>
              )
            case 'simple':
              return (
                <>
                  <Button type='button' variant='outline' size='sm' onClick={() => {
                    save({ ...consent, contact: true })
                    onConsent?.()
                  }}>
                    {i18n.authorize}
                  </Button>
                  <Button type='button' variant='outline' size='sm' onClick={() => save({ ...consent, contact: false })}>
                    {i18n.refuse}
                  </Button>
                </>
              )
            default:
              return null
          }
        })()}
      </CardFooter>
    </Card>
  )
}

export function CookieConsentLink() {

  const i18n = useI18n(I18NLink)
  const { openCookiesConsentDialog } = useCookiesConsentDialog()
  const mounted = useMounted()
  if (!mounted) return null

  return (
    <Button variant='link' size='sm' className='text-xs text-muted-foreground hover:text-foreground'>
      <div onClick={e => { e.preventDefault(); openCookiesConsentDialog('welcome') }}>{i18n.cookiesConsent}</div>
    </Button>
  )
}
