'use client'

import { cn } from '@/lib/utils/utils'
import Link from 'next/link'
import ReactMarkdown from 'react-markdown'

interface MDOptions {
  strongWeight?: number,
  textAlign?: 'left' | 'right' | 'center' | 'justify',
  onClickLink?: (href: any) => void,
  target?: string,
  rel?: string,
  children?: any,
  className?: string
}

const DEFAULT_OPTIONS: MDOptions = {
  strongWeight: 700,
  textAlign: 'left',
  target: '_self'
}

export default function MD(props: MDOptions) {

  props = { ...DEFAULT_OPTIONS, ...props }
  // const { theme } = useTheme()

  return (
    <ReactMarkdown className={cn(props.className)} components={{
      p: ({ node, ..._props }) => <p style={{ textAlign: props.textAlign }} {..._props} />,
      strong: ({ node, ..._props }) => <span style={{ fontWeight: props.strongWeight }} {..._props} />,
      em: ({ node, ..._props }) => <span className='text-primary dark:text-primaryBright' {..._props} />,
      ul: ({ node, ordered, ..._props }) => <ul style={{ listStyle: 'disc inside' }} {..._props} />,
      a: ({ node, href, ..._props }) => <Link target={props.target} rel={props.rel  } className='dark:primaryBright cursor-pointer text-primary hover:underline' onClick={() => { if (props.onClickLink) props.onClickLink(href) }} href={href as string} {..._props} />,
      hr: () => <hr style={{ width: '100%', height: '1px', backgroundColor: 'gray', opacity: '0.1', margin: '8px 0' }} />,
      h1: ({ node, ..._props }) => <h1 style={{ fontSize: '2rem' }} {..._props} />,
      h2: ({ node, ..._props }) => <h2 style={{ fontSize: '1.5rem' }} {..._props} />,
      h3: ({ node, ..._props }) => <h3 style={{ fontSize: '1.25rem' }} {..._props} />,
      code: ({ node, ..._props }) => <span style={{ background: '#fff58599' }} {..._props} />
    }}>
      {props.children}
    </ReactMarkdown>
  )

}
