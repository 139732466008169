'use client'

import { cn } from '@/lib/utils/utils'
import { FlexCol } from '../ui/flex'

export function Section({ id, children, containerClassName, contentClassName }: { id?: string, children: React.ReactNode, containerClassName?: string, contentClassName?: string }) {

  return (
    <div id={id ? id : 'SECTION'} className={cn('w-full overflow-hidden', containerClassName)}>
      <FlexCol id='SECTION_CONTENT' className={cn('border-1 m-auto w-full max-w-7xl items-center gap-8 p-4 py-8 sm:gap-16 sm:py-16', contentClassName)}>
        {children}
      </FlexCol>
    </div>
  )
}
