'use client'

import { cn } from '@/lib/utils/utils'
import { I18nDictionary, useI18n } from '../../i18n/lang-context'
import { Button } from '../../ui/button'
import { useLBCalendarDialog } from './lb-calendar-dialog'

const I18N: I18nDictionary = {
  action: {
    en: 'Book a Call',
    fr: 'Réserver un appel'
  },
  becomePartner: {
    en: 'Become a Partner →',
    fr: 'Devenez partenaire →'
  }
}

export function LBAction({ className, _I18N }: { className?: string, _I18N?: I18nDictionary }) {

  const i18n = useI18n(_I18N || I18N)
  const { openLBCalendarDialog } = useLBCalendarDialog()

  return (
    <Button className={cn('p-8 text-lg', className)}
      onClick={() => openLBCalendarDialog()}
      id='Call Booking'>
      {i18n.action}
    </Button>
  )
}

export function LBBecomePartner({ className }: { className?: string }) {

  const i18n = useI18n(I18N)
  const { openLBCalendarDialog } = useLBCalendarDialog()

  return (
    <div className={cn('cursor-pointer font-medium text-primary hover:underline dark:text-primaryBright', className)} onClick={() => openLBCalendarDialog()}>
      {i18n.becomePartner}
    </div>
  )
}
