'use client'

import { useEffect, useState } from 'react'

// A useEffect that stop listening to async calls when unmounted
//////////////////////////////////////////////////////////////
export const useAsync = (asyncFn: any, onSuccess: any, deps: any[] = []) => {
  useEffect(() => {
    let isActive = true
    asyncFn().then((data: any) => {
      if (isActive) onSuccess(data)
    })
    return () => { isActive = false }
  }, deps)
}

export const useMounted = () => {
  const [mounted, setMounted] = useState(false)
  useEffect(() => {
    setMounted(true)
  }, [])
  return mounted
}
