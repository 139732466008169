'use client'

// context/ConsentContext.tsx
import React, { createContext, useContext, useState, useEffect, ReactNode } from 'react'
import Script from 'next/script'
import { siteConfig } from '@/config/site.config'

export const CONSENTS = [
  'essential',
  'analytics',
  'contact'
] as const

export const CONSENT_COOKIE_I18N = {
  essential: {
    title: { en: 'Essential features', fr: 'Fonctionnalités essentielles' },
    desc: { en: 'Essential features', fr: 'Fonctionnalités essentielles' }
  },
  analytics: {
    title: { en: 'Analytics', fr: 'Mesure d\'audience' },
    desc: { en: 'Analytics', fr: 'Mesure d\'audience' }
  },
  contact: {
    title: { en: 'Communication', fr: 'Communication' },
    desc: { en: 'Follow up on a request', fr: 'Prendre contact suite à une demande' }
  }
}

export interface ConsentState {
  essential: boolean
  analytics: boolean
  contact: boolean
  expiresAt?: string
  init?: boolean
}

export const GTM_ID = siteConfig.gtmId

// Maximum authorized duration is 13 months in France so 365 days is fine
export const CONSENT_COOKIE_DURATION = 365

export const calculateExpiresAt = (days: number): string => {
  const date = new Date()
  date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000)
  return date.toUTCString()
}

export const CONSENT_COOKIE_ALL = (): ConsentState => ({
  essential: true,
  analytics: true,
  contact: true
})

export const CONSENT_COOKIE_NONE = (): ConsentState => ({
  essential: true,
  analytics: false,
  contact: false
})

interface ConsentContextType {
  consent: ConsentState
  setConsent: (consent: ConsentState) => void
}

const defaultState = {
  essential: true, 
  analytics: false, 
  contact: false, 
  expiresAt: 'null',
  init: false
}

const ConsentContext = createContext<ConsentContextType>({
  consent: defaultState,
  setConsent: () => {}
})

const CONSENT_COOKIE_NAME = siteConfig.consentCookie

type WindowWithDataLayer = Window & {
  dataLayer: Record<string, any>[]
}
declare const window: WindowWithDataLayer

const setConsentCookie = (name: string, consent: ConsentState) => {
  const cookieValue = encodeURIComponent(JSON.stringify(consent))
  document.cookie = `${name}=${cookieValue}; expires=${consent.expiresAt}; path=/`
}
const getConsentCookie = (name: string): ConsentState | null => {
  const cookieArr = document.cookie.split(';').map(cookie => cookie.trim())
  const consentCookie = cookieArr.find(cookie => cookie.startsWith(`${name}=`))
  
  if (!consentCookie) return null
  
  const [, cookieValue] = consentCookie.split('=')
  if (!cookieValue) return null
  
  try {
    const consentState: ConsentState = JSON.parse(decodeURIComponent(cookieValue))
    return consentState
  } catch (error) {
    console.error('Error parsing consent cookie:', error)
    return null
  }
}

export const ConsentContextProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const [consent, setConsent] = useState<ConsentState>(() => {

    if (typeof window !== 'undefined') {
      const storedConsent = getConsentCookie(CONSENT_COOKIE_NAME)
      return storedConsent ? storedConsent : defaultState
    }
    return defaultState
  })
  useEffect(() => {
    // Update localStorage whenever the consent state changes and notify GTM
    if (typeof window !== 'undefined') {
      setConsentCookie(CONSENT_COOKIE_NAME, consent)      
      window.dataLayer?.push({
        event: 'consent_update',
        analytics: consent.analytics
      })    
    }
    //console.log({GTM_ID})
  }, [consent])

  return (
    <ConsentContext.Provider value={{ consent, setConsent }}>
      <Script
        id="gtm-script"
        strategy="afterInteractive"
        dangerouslySetInnerHTML={{
          __html: `
          (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
          new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
          j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
          'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
          })(window,document,'script','dataLayer', '${GTM_ID}');
          `
        }}
      />
      {children}
    </ConsentContext.Provider>
  )
}

export const useConsent = () => {
  const context = useContext(ConsentContext)
  if (!context)
    throw new Error('useConsent must be used within a ConsentContextProvider')
  return context
}