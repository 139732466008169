'use client'

import { isBrowser } from '@/lib/utils/utils'
import { useEffect, useState } from 'react'

// Types definition
export type WindowSize = { width: number, height: number }
export type ScreenSizes = 'sm' | 'md' | 'lg' | 'xl' | '_2xl'
export type ScreenWidths = { [key in ScreenSizes]: number }
export type Screen = { [key in ScreenSizes | 'w' | 'h']: any }

// CONSTANTS
export const SCREEN_WIDTHS: ScreenWidths = { sm: 640, md: 768, lg: 1024, xl: 1280, _2xl: 1536 }

/**
 * Returns an object representing the current size of the browser window.
 *
 * @return {WindowSize} An object with the properties 'width' and 'height',
 * representing the current width and height of the browser window.
 */
export function useWindowSize(): WindowSize {

  const [windowSize, setWindowSize] = useState<WindowSize>({ width: 0, height: 0 })

  useEffect(() => {
    function handleResize() {
      const { innerWidth: width, innerHeight: height } = isBrowser() ? window : { innerWidth: 0, innerHeight: 0 } as any
      setWindowSize({ width, height })
    }
    handleResize()
    window.addEventListener('resize', handleResize)
    return () => window.removeEventListener('resize', handleResize)
  }, [])

  return windowSize
}

/**
 * Returns an object with boolean values indicating whether the current screen
 * size is greater than or equal to the specified screen size.
 *
 * @return {Screen} object with boolean values for sm, md, lg, xl, and _2xl screen sizes
 */
export function useScreen(): Screen {
  const { width, height } = useWindowSize()
  const screen = (screenSize: ScreenSizes): boolean => width >= SCREEN_WIDTHS[screenSize]
  return { sm: screen('sm'), md: screen('md'), lg: screen('lg'), xl: screen('xl'), _2xl: screen('_2xl'), w: width, h: height }
}
