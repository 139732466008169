import { MutableRefObject, useEffect, useState } from 'react'

export enum ScrollDirection {
  up = 'up',
  down = 'down'
}

export const useScroll = (threshold: number = 0) => {

  const [scrollY, setScrollY] = useState(0)
  const [scrollDir, setScrollDir] = useState(ScrollDirection.up)
  const [scrollYMax, setScrollYMax] = useState(0)

  useEffect(() => {

    let lastScrollY = window.scrollY
    let ticking = false

    const updateScroll = () => {
      if (Math.abs(window.scrollY - lastScrollY) >= threshold) {
        setScrollY(window.scrollY)
        setScrollYMax(window.document.body.scrollHeight - window.innerHeight)
        setScrollDir(window.scrollY > lastScrollY ? ScrollDirection.down : ScrollDirection.up)
        lastScrollY = Math.abs(window.scrollY)
      }
      ticking = false
    }

    const onScroll = () => {
      if (!ticking) {
        window.requestAnimationFrame(updateScroll)
        ticking = true
      }
    }

    window.addEventListener('scroll', onScroll)

    return () => window.removeEventListener('scroll', onScroll)
  }, [scrollY])

  return {scrollY, scrollYMax, scrollDir}
}

export const DEFAULT_INTERSECTION_OPTIONS = {
  root: null,
  rootMargin: '0px',
  threshold: 0.0
}

export const useScrollIntersection = (ref: MutableRefObject<any>, delay: number = 0, intersectOptions: any = DEFAULT_INTERSECTION_OPTIONS) => {

  const [show, setShow] = useState(false)
  const [animation, setAnimation] = useState(false)

  const animate = () => show && animation

  const resetAnimation = () => {
    setAnimation(false)
    setTimeout(() => {
      setAnimation(true)
    }, delay)
  }

  useEffect(() => {
    resetAnimation()
    const options = Object.assign({}, DEFAULT_INTERSECTION_OPTIONS, intersectOptions)
    const observer = new IntersectionObserver((entries: any[]) => setShow(entries[0].isIntersecting), options)
    if (ref.current) observer.observe(ref.current)
    return () => {
      if (ref.current) observer.unobserve(ref.current)
    }
  }, [])

  return {show, animate, resetAnimation}
}
